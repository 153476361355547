.rbc-month-view {
  border-radius: 20px !important;
}

.rbc-header {
  padding: 4px;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  border: "0px #fff";
}

.columnheader {
  text-transform: capitalize;
}

.rbc-event {
  background-color: transparent !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-button-link {
  user-select: none;
}
